import React from "react"
import Layout from "../components/Layout"
import PageHeader from '../components/PageHeader'
import ContainerWrapper from '../components/Container'
import Link from "gatsby-link"

const About = () => (
  <Layout>
    <PageHeader>
      <h1>Page not found</h1>
    </PageHeader>
    <ContainerWrapper>
      <p>Sorry, that page doesn't exist</p>
      <Link to="/">Go Home</Link>
    </ContainerWrapper>

  </Layout>
)

export default About;
